<template>
  <div class="d-flex flex flex-column map-wrapper">
    <GmapMap
      v-if="!isMobile"
      :center="{ lat: position.lat, lng: position.lng }"
      :zoom="16"
      :options="{ disableDefaultUI: true, styles }"
      map-type-id="terrain"
      style="min-height: 500px"
    >
      <GmapMarker :key="1" :position="position" />
    </GmapMap>
    <v-col
      ref="reservationInfo"
      class="d-flex reservation-info-wrapper pa-0"
      cols="12"
      sm="7"
      md="5"
      lg="4"
    >
      <v-card elevation="0" style="height: 100%" class="overflow-auto">
        <v-overlay
          v-show="shouldPayAccommodation"
          color="white"
          light
          absolute
          :opacity="0.9"
        >
          <v-card
            elevation="0"
            max-width="550"
            min-width="300"
            class="d-flex transparent"
          >
            <v-row class="justify-center align-center flex-column pa-6">
              <v-icon color="warning" size="150" class="pa-2">
                fas fa-credit-card
              </v-icon>
              <div class="pb-6 text-center black--text text-subtitle-2">
                {{ $t('PleaseCompleteBookingPayment') }}
              </div>
              <div>
                <v-btn
                  :to="`/tourist/trips/${reservation.confirmation_code}/checkout/${accommodation.payment_intent_id}`"
                  link
                  color="warning"
                >
                  {{ $t('ProceedToCheckout') }}
                </v-btn>
              </div>
            </v-row>
          </v-card>
        </v-overlay>
        <v-overlay
          v-if="shouldPayDeposit"
          absolute
          color="white"
          class="rounded-xl"
          light
          :opacity="0.9"
        >
          <v-row class="justify-center pa-6">
            <v-icon color="warning" size="100" class="pa-6">
              fas fa-unlock-alt
            </v-icon>
            <div class="px-4 bolder text-subtitle-1 black--text">
              {{ $t('ReservationDetailsAvailability') }}
            </div>
            <div>
              <v-btn
                :to="`/tourist/trips/${reservation.confirmation_code}/checkout/${deposit.payment_intent_id}`"
                link
                color="warning"
              >
                {{ $t('PayDeposit') }}
              </v-btn>
            </div>
          </v-row>
        </v-overlay>
        <v-carousel
          v-if="first5Pics.length"
          :height="carouselHeight"
          hide-delimiter-background
          next-icon="chevron_right"
          prev-icon="chevron_left"
        >
          <v-carousel-item v-for="(slide, i) in first5Pics" :key="i">
            <v-img
              width="100%"
              height="100%"
              :src="slide.large || slide.original"
            />
          </v-carousel-item>
        </v-carousel>
        <v-img v-else :src="reservation.listing.picture"> </v-img>
        <v-overlay
          v-if="isTouristLoading"
          :opacity="0.1"
          absolute
          class="flex-center"
        >
          <v-progress-circular color="primary" indeterminate size="50" />
        </v-overlay>
        <div v-else class="d-flex flex flex-column overflow-y-auto pa-3">
          <div class="d-flex">
            <v-btn
              class="me-4"
              icon
              :href="googleMapsLink"
              target="_blank"
              color="primary"
            >
              <v-icon> fas fa-map-marker-alt </v-icon>
            </v-btn>
            <div>
              <div class="mt-0 text-subtitle-1 text-sm-h6">
                {{ title }}
              </div>
              <div class="text-body-2 secondary--text">
                {{ reservation.listing.city_name }}
              </div>
            </div>
          </div>
          <v-divider class="my-4" />
          <v-row class="px-3" no-gutters>
            <v-col cols-6 class="pl-3">
              <CheckDateBox
                :title="$t('Check-in')"
                :date="reservation.check_in"
                :timezone="listing.timezone"
                :time="touristsData.min_check_in_time_string"
                :planned-time="timesInfo.requested_check_in_time"
              />
            </v-col>
            <v-divider vertical />
            <v-col cols-6 class="pl-3">
              <CheckDateBox
                :title="$t('Checkout')"
                :date="reservation.check_out"
                :timezone="listing.timezone"
                :time="touristsData.max_check_out_time_string"
                :planned-time="timesInfo.requested_check_out_time"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div class="px-3">
            <div class="text-h6 font-weight-medium my-2">
              {{ $t('Getting there') }}
            </div>
            <div class="text-subtitle-1 font-weight-medium">
              {{ $t('Address') }}
            </div>
            <div class="text-caption">
              <a :href="googleMapsLink" target="_blank" class="black--text">{{
                reservation.listing.address
              }}</a>
            </div>
            <v-divider class="my-4" />
            <div v-if="touristsData.checkin_instructions">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="ma-0 pa-0"
                    expand-icon="mdi-menu-down"
                  >
                    <div>
                      <v-icon class="mr-2" small> $map_directions </v-icon>
                      {{ $t('Arrival Instructions') }}
                    </div></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <div
                      class="text-caption mt-2"
                      style="white-space: pre-line"
                      v-html="marked(touristsData.checkin_instructions)"
                    ></div>
                    <gallery
                      :thumbnails="true"
                      :cols="4"
                      :images="instructionsMedia"
                  /></v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider class="my-4" />
            </div>
            <div v-if="touristsData.checkout_instructions && isCloseToCheckout">
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="ma-0 pa-0"
                    expand-icon="mdi-menu-down"
                  >
                    <div>
                      <v-icon class="mr-2 flip-horizontal" small>
                        $map_directions
                      </v-icon>
                      {{ $t('Departure Instructions') }}
                    </div></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <div
                      class="text-caption mt-2"
                      style="white-space: pre-line"
                      v-html="marked(touristsData.checkout_instructions)"
                    ></div>
                    <gallery
                      :thumbnails="true"
                      :cols="4"
                      :images="instructionsMedia"
                  /></v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-divider class="my-4" />
            </div>
          </div>
          <v-row class="no-gutters">
            <v-col cols="12">
              <div
                v-if="touristsData.error"
                class="text-caption font-weight-medium warning--text pb-1"
              >
                <v-icon x-small class="pr-1" color="warning">
                  fas fa-exclamation-triangle
                </v-icon>
                {{ $t(touristsData.error) }}
                {{ errorCheckinDate }}
                {{ touristsData.min_check_in_time_string }}
              </div>
            </v-col>
            <v-col>
              <BookingCodes
                :disabled="Boolean(touristsData.error)"
                :tourist-data="touristsData"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-row class="px-3" no-gutters>
            <v-col cols="12">
              <PurchasesReview
                v-if="purchasedItems.length"
                :items="purchasedItems"
              />
            </v-col>
            <v-col
              v-if="!isReservationPassed"
              class="d-flex justify-center flex-column text-center"
            >
              <div>
                <v-btn
                  v-if="storeEnabled && touristsData.has_store_items"
                  dark
                  small
                  class="white--text font-weight-bold ma-2"
                  depressed
                  rounded
                  :to="{
                    name: 'tourist-store',
                    id: reservation.confirmation_code,
                  }"
                  color="pink lighten-1"
                >
                  <v-icon small class="me-2">mdi-gift</v-icon>
                  {{ $t('BrowseVacationAddons') }}
                </v-btn>
              </div>
              <travel-guide-modal
                v-if="travelGuideUrl"
                :travel-guide-url="travelGuideUrl"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div class="d-flex px-3">
            <pre-check-in-summary />
          </div>
        </div>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import CheckDateBox from 'components/common/check-date-box'
import take from 'lodash/fp/take'
import BookingCodes from 'components/reservations/booking-codes'
import PreCheckInSummary from 'components/reservations/pre-check-in-summary'
import mapStyles from 'components/map-style'
import PurchasesReview from 'components/store/purchases-review'
import featureFlagsMixin from '@/components/mixins/featureFlagsMixin'
import CommonFunctions from '@/components/mixins/common-functions'
import moment from 'moment'
import deviceMixin from 'components/mixins/deviceMixin'
import Gallery from 'components/common/gallery.vue'
import TravelGuideModal from './travel-guide-modal.vue'
import { marked } from 'marked'
import { mapGetters } from 'vuex'

const renderer = new marked.Renderer()
renderer.link = function (href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank" rel="noopener noreferrer">${text}</a>`
}

export default {
  name: 'ReservationInfo',
  components: {
    PurchasesReview,
    PreCheckInSummary,
    BookingCodes,
    CheckDateBox,
    Gallery,
    TravelGuideModal,
  },
  mixins: [featureFlagsMixin, CommonFunctions, deviceMixin],
  data() {
    return {
      dialog: false,
      carouselHeight: 0,
    }
  },
  computed: {
    ...mapGetters(['isTouristLoading']),
    styles() {
      return mapStyles
    },
    pre_check_in() {
      return this.reservation.pre_check_in || {}
    },
    isReservationPassed() {
      return moment(this.reservation.check_out).isBefore(moment())
    },
    isCloseToCheckout() {
      return moment(this.reservation.check_out).isBefore(
        moment().add(12, 'days')
      )
    },
    googleMapsLink() {
      return (
        this.touristsData.custom_address_url ||
        `https://www.google.com/maps/dir/?api=1&destination=${this.reservation.listing.address}`
      )
    },
    travelGuideUrl() {
      const checkinUrl =
        this.reservation.checkin_info &&
        this.reservation.checkin_info.travel_guide_url
      const listingUrl = this.touristsData && this.touristsData.travel_guide_url
      return listingUrl || checkinUrl
    },
    instructionsMedia() {
      return (this.touristsData.checkin_instructions_media || []).map(
        img => img.src
      )
    },
    touristsData() {
      return this.$store.getters.reservationInfo
    },
    errorCheckinDate() {
      if (!this.reservation.planned_arrival) return ''
      return moment(this.reservation.check_in)
        .tz(this.listing.timezone)
        .format('MM/DD/YYYY')
    },
    acceptDeposit() {
      return (
        this.reservation.checkin_info &&
        this.reservation.checkin_info.accept_deposit_payments
      )
    },

    shouldPayDeposit() {
      return (
        !this.pre_check_in.unlock_portal_access &&
        this.acceptDeposit &&
        this.$store.state.tourist.shouldPlaceDeposit
      )
    },
    acceptAccommodation() {
      return (
        this.reservation.checkin_info &&
        this.reservation.checkin_info.accept_portal_payments
      )
    },
    shouldPayAccommodation() {
      return (
        !this.pre_check_in.unlock_portal_access &&
        this.acceptAccommodation &&
        this.reservation.is_merchant &&
        this.reservation.balance_due > 0 &&
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            intent.status === 'requires_source'
        )
      )
    },
    reservation() {
      return (
        this.$store.getters.currentReservation || {
          listing: { pictures: [] },
          payment_intents: [],
        }
      )
    },
    listing() {
      return this.reservation.listing
    },
    position() {
      if (!this.touristsData) return null
      const url = this.touristsData.custom_address_url
      if (url && url.split('@')[1]) {
        const [lat, lng] = url.split('@')[1].split(',')
        return { lat: parseFloat(lat), lng: parseFloat(lng) }
      }
      const { lat, lng } = this.reservation.listing
      return { lat, lng }
    },
    purchasedItems() {
      return this.$store.getters.purchasedItems
    },
    deposit() {
      return (
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'deposit' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) || {}
      )
    },
    accommodation() {
      return (
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) || {}
      )
    },
    pictures() {
      return this.reservation.listing.pictures.filter(
        p => p.thumbnail && p.original
      )
    },
    first5Pics() {
      return take(5, this.reservation.listing.pictures)
    },
    preCheckIn() {
      return this.reservation.pre_check_in || {}
    },
    title() {
      const { nickname, hotel_name } = this.reservation.listing
      const { checkin_info } = this.reservation
      const hide_hotel_units = checkin_info && checkin_info.hide_hotel_units
      return (hide_hotel_units && hotel_name) || nickname
    },
    timesInfo() {
      return this.touristsData.times_info || {}
    },
  },
  watch: {
    preCheckIn(preCheckIn) {
      if (preCheckIn) {
        this.validatePreCheckIn(preCheckIn.status)
      }
    },
  },
  created() {
    window.addEventListener('resize', this.resizeEvent)
  },
  mounted() {
    this.resizeEvent()
  },
  beforeCreate() {
    this.$store.dispatch('getReservations')
  },
  beforeMount() {
    if (this.reservation.pre_check_in) {
      this.validatePreCheckIn(this.reservation.pre_check_in.status)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeEvent)
  },
  methods: {
    marked(val) {
      return val ? marked(val, { renderer: renderer }) : val
    },
    validatePreCheckIn(status) {
      if (status !== 'Done') {
        this.$router.push({
          name: 'pre-check-in',
          params: {
            id: this.reservation.confirmation_code,
            message:
              'Please complete the pre-check-in process to access your reservation details.',
          },
        })
      }
    },
    goBack() {
      this.$router.back()
    },
    showDirections() {
      window.open(
        this.googleMapsLink,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    resizeEvent() {
      this.carouselHeight = this.$refs.reservationInfo.clientWidth * 0.65
    },
  },
}
</script>

<style scoped>
.reservation-info-wrapper {
  /*background-color: #9898981f;*/
  position: absolute;
  height: 100%;
}
.gallery-container {
  min-height: 300px;
}
.map-wrapper {
  position: relative;
}
.back-button {
  position: absolute;
  left: 10px;
}
.border-img {
  border: 3px solid white;
}
.flip-horizontal {
  transform: scaleX(-1);
}
.v-expansion-panel >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
