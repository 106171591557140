import axios from 'axios'
import router from '@/router'
export default {
  state: {
    user: null,
    userFetched: false,
    configuration: {
      logo_url: '',
      company_name: '',
      currency: 'USD',
      timezone: '',
    },
  },
  mutations: {
    updateUser(state, data) {
      state.user = data
      state.userFetched = true
    },
    updateConfiguration(state, value) {
      state.configuration = value
    },
  },
  actions: {
    updateLocale: function (context, { id, ...data }) {
      return axios
        .put(`/api/users/${id}/locale`, data)
        .then(() => {
          context.dispatch('getCurrentUser', null, { root: true })
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    async validateUser(_, { id }) {
      return axios.get('/api/tourist/user/validate', {
        params: { res_id: id },
      })
    },
    async getCurrentUser({ commit }) {
      try {
        const { data } = await axios.get(`/api/tourist/user`)
        if (data) {
          commit('updateUser', data)
        }
        return data
      } catch (err) {
        const route = router.currentRoute
        if (route.params.id) {
          const { data } = await axios.get('/api/tourist/user/validate', {
            params: { res_id: route.params.id },
          })
          if (data.redirect_url) {
            window.location.href = data.redirect_url
          }
        } else {
          throw err
        }
      }
    },
    async logout({ commit }) {
      await axios.post('/api/logout')
      commit('updateUser', null)
      router.push({ name: 'login' })
    },
    async getConfiguration({ commit, state }) {
      const { data } = await axios.get(`/api/config`)
      window.config = Object.assign({}, state.configuration, data)
      commit('updateConfiguration', data)
    },
    async logAction({ state, getters, rootState }, { eventType, storeItemId }) {
      window.hj('event', eventType)
      const { guest_id } = state.user
      const reservation_id = getters.currentReservation
        ? getters.currentReservation.id
        : rootState.cart.currentPurchase &&
          rootState.cart.currentPurchase.reservation_id
      const deviceType = /Android|webOS|iPhone|iPad/i.test(navigator.userAgent)
        ? 'phone'
        : 'desktop'
      axios.post(`/api/tourist/reservations/${reservation_id}/log`, {
        reservation_id,
        guest_id,
        device_type: deviceType,
        event_type: eventType,
        store_item_id: storeItemId,
      })
    },
  },
  getters: {
    user(state) {
      return state.user
    },
    userFetched(state) {
      return state.userFetched
    },
  },
}
